@import url('https://fonts.googleapis.com/css2?family=Inter:ital,opsz,wght@0,14..32,100..900;1,14..32,100..900&display=swap');
* {
    font-family: "Inter", serif;
}
body {
    background: #f5f5f5;
}
#image-product {
    width: 150px;
    height: 150px;
    background-color: #FFF;
    border-radius: 10px;
}
#image-product img {
    width: 80%;
    height: 80%;
    margin-left: 10%;
    margin-top: 10%;
}
#product-name {
    font-size: 17px;
    font-weight: 800;
    color: #000;
}
#product-client {
    font-size: 13px;
    font-weight: 400;
    color: #717171;
    margin-top: 7px;
}
#product-value {
    font-size: 18px;
    color: #1773B0;
    font-weight: 900;
    margin-top: 13px;
}
#product-money {
    font-size: 13px;
    color: #1773B0;
    font-weight: 400;
    margin-top: 8px;
}
#box {
    background-color: #FFF;
    padding: 40px;
    margin-top: 17px;
    border-radius: 10px;
}
.title-card {
    font-size: 19px;
    font-weight: 800;
    color: #333;
    margin-bottom: 10px;
}

input {
    outline: 0;
}
.mui-input .MuiOutlinedInput-input {
    font-family: "Inter", serif;
    font-weight: 300;
    padding-top: 20px;
    padding-bottom: 10px;
    font-size: 13px;
}
.input label {
    font-size: 10px;
    margin-top: 15px;
    margin-left: 13.1px;
    position: absolute;
    color: #707070;
    font-weight: 300;
    z-index: 10;
}
.subtitle-card {
    color: #707070;
    font-size: 12px;
    margin-top: -10px;
    margin-bottom: 10px;
}
.title-payment {
    font-size: 14px;
    font-weight: 400;
    color: #333;
}
#btn-payment {
    width: 100%;
    padding: 15px;
    border: none;
    background-color: #caa651;
    color: #FFF;
    border-radius: 5px;
    font-weight: 700;
    margin-top: 10px;
    cursor: pointer;
    transition: 0.5s;;
}
#btn-payment:hover {
    background-color: #e7c458;
}
#details-security {
    padding: 20px;
    margin-top: 10px;
    background-color: #F0F0F0;
    border: 1px solid #DEDEDE;
    border-radius: 5px;
}
#details-security #content {
    font-size: 13px;
    color: #707070;
}
#details-security #content-footer {
    font-size: 10px;
    margin-top: 19px;
    color: #707070;
}
#logo {
    width: 130px;
    position:relative;
    left: 50%;
    margin-left: -75px;
    margin-top: 50px;
    margin-bottom: 50px;
}
#timer {
    background-color: #db4133;
    border-radius: 10px;
    display: none;
}
#timer #title {
    font-size: 17px;
    font-weight: 300;
    color: #FFF;
}
#timer #sub-title {
    font-size: 13px;
    font-weight: 700;
    color: #FFF;
}
.order-bump img {
    width: 100px;
    height: 100px;
    border-radius: 5px;
}
.order-bump .title-order {
    font-weight: 900;
    color: #caa651;
    margin-left: 10px;
}
#title-order {
    margin-top: 15px;
    font-weight: 600;
    font-size: 13px;
}
#subtitle-order {
    font-weight: 400;
    font-size: 10px;
    margin-bottom: 10px;
}
.product-order {
    font-size: 12px;
    margin-top: 5px;
    margin-bottom: 5px;
    font-weight: 600;
    margin-left: 10px;

}
.btn-order-value {
    margin-left: 10px;
    font-size: 13px;
    margin-bottom: 5px;
}
.price-new {
    color: #4E9F50;
}
.btn-order {
    width: 150px;
    border: none;
    margin-left: 10px;
    background-color: #4E9F50;
    padding: 5px;
    border-radius: 5px;
    cursor: pointer;
    font-size: 10px;
    color: #FFF;
    font-weight: 500;
}
.order-bump {
    border: 2px solid transparent;
    cursor: pointer;
    padding: 10px;
    display: flex;
    align-items: center;
    gap: 10px;
    transition: all 0.3s;
    width: calc(100% - 24px);
    margin-top: 10px;
    padding: 10px;
    border: 2px solid #caa651;
    border-radius: 5px;
    display: flex;
    align-items: center;
  }
  
  .order-bump.selected {
    border: 5px #4E9F50 solid;
    background: #f0fff0;
    width: calc(100% - 30px);
  }
  .green {
    color: #4E9F50 !important;
  }
  
  .price-original {
    font-weight: 800;
    color: red;
  }
  .subtitle-freight {
    font-size: 12px;
    color: #878787;
  }